.form-check .form-check-input {
  border-color: #031B28dc !important;
}

.form-check .form-check-input:checked {
  background-color: #031B28dc !important;
  border-color: #031B28dc !important;
}

.form-check .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(3, 27, 40, 0.25) !important;
}