.custom-datepicker .antd-picker {
  background-color: #fafafa;
}

.custom-datepicker .antd-picker-cell-in-view:hover {
  background-color: #f5a623;
}

.custom-datepicker .antd-picker-cell-selected {
  background-color: #031B28;
  color: #fff;
}