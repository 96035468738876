/* src/components/SearchFilters/SearchFilters.css */
@media screen and (max-width: 768px) {
  .desktop-search-component {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .desktop-search-mobile {
    display: none;
  }
}

.search-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.search-filters input,
.search-filters select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-filters button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-filters button:hover {
  background-color: #0056b3;
}
